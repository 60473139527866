import "./App.css";
import PhotoView from "./PhotoView";
import HomeView from "./HomeView";
import { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useMainContext } from "./MainContext";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
    const {
        loadingSpinnerVisible, 
        currentPage,
        takenPhoto
    } = useMainContext();

    useEffect(() => {
        const appHeight = () => {
            const doc = document.documentElement
            doc.style.setProperty("--app-height", `${window.innerHeight}px`)
        }
        window.addEventListener("resize", appHeight);
        appHeight();
    }, []);

    return (
        <div className="App container">
            {
                currentPage === "home-view" && (
                    <HomeView/>
                )
            }
            {
                currentPage === "photo-view" && takenPhoto !== "" && (
                    <>
                        <PhotoView/>
                    </>
                )
            }
            {
                loadingSpinnerVisible && (
                    <div className="App-backdrop">
                        <div className="mx-auto mx-auto spinner-border text-light" role="status"></div>
                    </div>
                )
            }
        </div>
    );
}

export default App;
