import { useCallback, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import "./PhotoView.css";
import { useMainContext } from "./MainContext";
import { useEffect } from "react";
import { FormXAPIDetectDocument, FormXImgProc, FormXRect} from "@oursky/formx-mobile";

interface DetectedDocumentPhoto {
    document: FormXAPIDetectDocument;
    photo: string;
}

export default function PhotoView() {
    const {
        takenPhoto,
        detectedDocuments,
        backToHome
    } = useMainContext();

    const [detectedDocumentPhotos, setDetectedDocumentPhotos] = useState<DetectedDocumentPhoto[]>([]);

    const canvasRef = useRef<HTMLCanvasElement>(null);

    const render = useCallback(async (takenPhoto: string, documents: FormXAPIDetectDocument[]) => {

        const detectedDocumentPhotos = await Promise.all(documents.map(async (document) => {
            const boundingBox = FormXRect.fromBBox(document.bbox);
            const photo = await FormXImgProc.cropImage(takenPhoto, boundingBox);
            return {
                document,
                photo
            }
        }));

        setDetectedDocumentPhotos(detectedDocumentPhotos);
    }, []);

    useEffect(() => {
        render(takenPhoto, detectedDocuments);
    }, [takenPhoto, detectedDocuments, render]);

    return (
        <>
            <div className="d-flex flex-column h-100">
                <div className="flex-grow-1 p-2 position-relative">
                    <div className="position-absolute top-0 bottom-0 start-0 end-0">
                        <canvas className="w-100 h-100 PhotoView-Photo" ref={canvasRef}/>
                    </div>
                </div>
                <div className="row p-2">
                    <>
                    {
                        detectedDocumentPhotos.map((detectedDocumentPhoto, index) => {
                            return (
                                <div key={index}>
                                    <h2 className="p-2">Type: {detectedDocumentPhoto.document.type}</h2>
                                    <img src={detectedDocumentPhoto.photo} className="PhotoView-DetectedPhoto w-100"/>
                                    <hr></hr>
                                </div>
                            )
                        })
                    }
                    </>
                </div>
                <div className="row p-2">
                    <div className="text-center">
                        <Button onClick={backToHome} className="m-2">Back</Button>
                    </div>
                </div>
            </div>
        </>
    );

}