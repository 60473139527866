const DEFAULT_API_HOST = "https://worker.formextractorai.com";

export interface FormXAPIDetectDocument {
    bbox: number[];
    bbox_score: number;
    type: string;
    type_score: number;
}

export interface FormXAPIDetectDocumentResponse {
    status: string;
    documents: FormXAPIDetectDocument[];
}

export class FormXAPIClient {
    
    formId: string

    accessToken: string

    apiHost = DEFAULT_API_HOST

    constructor(formId: string, accessToken: string, apiHost?: string) {
        this.formId = formId;
        this.accessToken = accessToken;
        if (apiHost) {
            this.apiHost = apiHost;
        }
    }
    
    async detectDocument(base64Image: string): Promise<FormXAPIDetectDocumentResponse> {
        const url = `${this.apiHost}/detect-documents`;

        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "image/jpeg",
                "X-WORKER-TOKEN": this.accessToken,
                "X-WORKER-ENCODING": "base64"
            },
            body: base64Image
        });
        return await response.json();
    }
}