import { FormXRect } from "./FormXRect";

export class FormXImgProc {
    static async cropImage(dataUrl: string, bounding: FormXRect): Promise<string> {
        return new Promise(resolve => {
            const img = document.createElement("img");
            img.onload = () => {
                const canvas = document.createElement("canvas");
                canvas.width = bounding.width;
                canvas.height = bounding.height;
                const ctx = canvas.getContext("2d");
                if (ctx === null) {
                    throw new Error("Failed to get canvas context");
                }
                ctx.drawImage(img, bounding.x, bounding.y, bounding.width, bounding.height, 
                    0, 0, canvas.width, canvas.height);
                resolve(canvas.toDataURL("image/jpeg"));
                canvas.remove();
                img.remove();
            }
            img.src = dataUrl;
        });
    }    
}