import { createContext, useContext, useCallback, useState, useMemo } from "react";
import {FormXAPIClient, FormXAPIDetectDocument} from "@oursky/formx-mobile";

function useMakeContext() {
    const [loadingSpinnerVisible, setLoadingSpinnerVisible] = useState(false);
   
    const [currentPage, _setCurrentPage] = useState("home-view");
    const [takenPhoto, setTakenPhoto] = useState<string>("");
    const [detectedDocuments, setDetectedDocuments] = useState<FormXAPIDetectDocument[]>([]);

    const setCurrentPage = useCallback((page: string) => {
        setLoadingSpinnerVisible(false);
        _setCurrentPage(page);
    }, []);

    const backToHome = useCallback(() => {
        setCurrentPage("home-view");
    }, [setCurrentPage]);

    const detectDocument = useCallback((accessToken: string) => {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = "image/*";
        input.style.display = "none";
        input.onchange = async (event) => {
            const file = (event.target as HTMLInputElement).files?.[0] ?? null;
            if (!file) {
                return;
            }
            const reader = new FileReader();
            setLoadingSpinnerVisible(true);
            reader.onload = async (event) => {               
                const loadedPhoto = event.target?.result as string;
                const base64 = loadedPhoto.split(";base64,")[1];
                const apiClient = new FormXAPIClient("", accessToken);
                const response = await apiClient.detectDocument(base64);
                setTakenPhoto(loadedPhoto);
                setCurrentPage("photo-view");
                setDetectedDocuments(response.documents);
                input.remove();
                setLoadingSpinnerVisible(false);
            }
            reader.readAsDataURL(file);
        }
        document.body.appendChild(input);
        input.click();
    }, [setCurrentPage]);

    return useMemo(() => ({
        loadingSpinnerVisible,
        setLoadingSpinnerVisible,
        currentPage,
        setCurrentPage,
        detectDocument,
        takenPhoto,
        setTakenPhoto,
        backToHome,
        detectedDocuments
    }), [
        loadingSpinnerVisible,
        currentPage,
        takenPhoto,
        backToHome,
        detectedDocuments,
        detectDocument,
        setCurrentPage
    ]);
}

type MainContextValue = ReturnType<typeof useMakeContext>;

const MainContext = createContext<MainContextValue>(null as any);

interface Props {
    children: React.ReactNode;
}
 
export function useMainContext() {
    return useContext(MainContext);
}

export const MainContextProvider = (props: Props) => {
    const value = useMakeContext();
    return <MainContext.Provider {...props} value={value} />;
}
