import { useCallback } from "react";
import appConfig from "./appconfig.json";
import "./HomeView.css";
import Button from "react-bootstrap/Button";
import { useMainContext } from "./MainContext";

export default function HomeView() {
    const {
        detectDocument
    } = useMainContext();

    const onClick = useCallback(() => {
        detectDocument(appConfig.accessToken);  
    }, [detectDocument]);

    return (<div>
        <h1 className="p-2">FormX Mobile SDK Demo</h1>
        <hr className="my-4"></hr>
        <Button onClick={onClick}>Take/Pick Photo</Button>
    </div>)
}